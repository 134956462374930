// import * as React from 'react';
import { useEffect, useState } from 'react';

import Head from 'next/head';
// import Link from 'next/link';
import Image from 'next/image';
import { useRouter } from "next/router";

//custom components
import Header from 'components/sections/header';
import Form from 'components/sections/form';

import Navbar from 'components/navbar';
import Footer from 'components/footer';


//header
import videoPoster from '/assets/Pictures/wonderwall-lux/lux-banner.jpg';
import videoPosterMobile from '/assets/Pictures/wonderwall-lux/lux-banner.jpg';
// import videobg from '/assets/Pictures/wonderwall-lux/header-video.mp4';
import title from '/assets/Pictures/wonderwall-lux/title@2x-new.png';
//section images
import imgSection01 from "/assets/Pictures/wonderwall-lux/section-1-img.webp";
import imgSection02 from "/assets/Pictures/wonderwall-lux/section-2-img.webp";
import imgSection03 from "/assets/Pictures/wonderwall-lux/section-3-img.webp";


//common
import styles from 'styles/microsite.module.scss';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';

import { isMobileOnly } from "react-device-detect";

import { serverSideTranslations } from "next-i18next/serverSideTranslations";
import { useTranslation } from 'react-i18next';
export async function getServerSideProps({locale,query}){
  return{
    props:{
      query,
      ...(await serverSideTranslations(locale,['wonderwalllux','common','shine','ultimatt']))
    },
  }
}

function WonderwallLuxPage({props}){

  const {t} =  useTranslation();
  
  const {locale} = useRouter();

  const isMobile = isMobileOnly;

  const [gutter, setgutter] = useState();
  const [spacing, setspacing] = useState();

  //resize callback - start
  const useWidth = () => {
    if (process.browser) {
      const [width, setWidth] = useState(window.innerWidth);
      const handleResize = () => setWidth(window.innerWidth);
      useEffect(() => {
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
      }, [width]);
      return width;
    }
    return 0;
  };
  const windowWidth = useWidth();
  useEffect(() => {
    if (windowWidth < 480) {
      setgutter(true)
      setspacing(0)
    } else {
      setgutter(false)
      setspacing(9)
    }
  }, [windowWidth]);
  //resize callback - end
  
  //image blur start
  const shimmer = (w, h) => `
  <svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
      <linearGradient id="g">
        <stop stop-color="#333" offset="20%" />
        <stop stop-color="#222" offset="50%" />
        <stop stop-color="#333" offset="70%" />
      </linearGradient>
    </defs>
    <rect width="${w}" height="${h}" fill="#333" />
    <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
    <animate xlink:href="#r" attributeName="x" from="-${w}" to="${w}" dur="1s" repeatCount="indefinite"  />
  </svg>`
  const toBase64 = (str) =>
    typeof window === 'undefined'
      ? Buffer.from(str).toString('base64')
      : window.btoa(str)
  //image blur end

  return(
    <>
      <Head>
        <title>{t('common:wonderwall_lux')} | {t('common:site_title')}</title>
        <meta name="description" content={t('wonderwalllux:section_desc')} />
        <link rel="icon" href="/favicon.ico" />
      </Head>
      
      <Navbar/>
      
      <main className={`${styles.main} main_home`}>

        {/* <Header collection="shine" title={title} titleW="562" titleH="188" poster={videoPoster} bgvideo={videobg} youtube="https://www.youtube.com/watch?v=XpeRQHbZ14E"/> */}
        <Header collection="shine" titleText={t('wonderwalllux:section_title')} title={title} titleW="562" titleH="188" posterdesktop={videoPoster.src} postermobile={videoPosterMobile.src} youtube="https://www.youtube.com/watch?v=XpeRQHbZ14E" isimage="1"/>
        <div id="the-lifeshine-collection" className={styles.section}>

          <header className={styles.header}>
            <Container maxWidth="lg" className={`${styles.container_header} container_header`}>
              <Grid container direction="row" justifyContent="center" alignItems="center" spacing={0} className={`${styles.grid_container} grid_container`}>
                <Grid item xs={10} md={5} className={`${styles.grid_item} grid_item`}>
                  <h1 className={styles.header_title}>{t('wonderwalllux:section_title')}</h1>
                  <p className={styles.header_desc}>{t('wonderwalllux:section_desc')}</p>
                </Grid>
              </Grid>
            </Container>
          </header>

          <Container disableGutters={gutter} maxWidth="lg" component="article" className={`${styles.article_img} article_img`}>
            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} className={`${styles.grid_container} grid_container`}>

              <Grid item xs={12} md={8} className={`${styles.grid_img} grid_img`}>
                <Image placeholder="blur" src={imgSection01} width="862" height="559" layout="responsive" className="img_fluid" alt={t('wonderwalllux:article_01_title_a')+t('wonderwalllux:article_01_title_b')}  priority/> 
              </Grid>

              <Grid item xs={12} md={4} className={`${styles.grid_context} grid_context`}>
                <div className={`${styles.context_wr} context_wr`}>
                  <Typography variant="subtitle1" gutterBottom component="h2" className={`${styles.title} title`}>
                    <span className={`${styles.text_1} text_1`}>{t('wonderwalllux:article_01_title_a')}</span>
                    <span className={`${styles.text_2} text_2`}>{t('wonderwalllux:article_01_title_b')}</span>
                  </Typography>
                  <Typography variant="body1" gutterBottom paragraph={true} component="div" sx={{mb:0}} dangerouslySetInnerHTML={{__html: t('wonderwalllux:article_01_desc')}}>
                  </Typography>
                </div>
              </Grid>
              
            </Grid>
          </Container>

          <Container disableGutters={gutter} maxWidth="lg" component="article" className={`${styles.article_img} article_img`}>
            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} className={`${styles.grid_container} grid_container`}>

              <Grid item xs={12} md={8} className={`${styles.grid_img} grid_img`}>
                <Image placeholder="blur" src={imgSection02} width="891" height="562" layout="responsive" className="img_fluid" alt={t('wonderwalllux:article_02_title_a')+t('wonderwalllux:article_02_title_b')} /> 
              </Grid>

              <Grid item xs={12} md={4} className={`${styles.grid_context} grid_context`}>
                <div className={`${styles.context_wr} context_wr`}>
                  <Typography variant="subtitle1" gutterBottom component="h2" className={`${styles.title} title`}>
                    <span className={`${styles.text_1} text_1`}>{t('wonderwalllux:article_02_title_a')}</span>
                    <span className={`${styles.text_2} text_2`}>{t('wonderwalllux:article_02_title_b')}</span>
                  </Typography>
                  {t('wonderwalllux:article_02_desc') !=""? 
                    <Typography variant="body1" gutterBottom paragraph={true} component="div" sx={{mb:0}} dangerouslySetInnerHTML={{__html: t('wonderwalllux:article_02_desc')}}>
                    </Typography>
                    :
                    ""}
                </div>
              </Grid>
              
            </Grid>
          </Container>

          <Container disableGutters={gutter} maxWidth="lg" component="article" className={`${styles.article_img} article_img`}>
            <Grid container direction="row" justifyContent="center" alignItems="stretch" spacing={0} className={`${styles.grid_container} grid_container`}>

              <Grid item xs={12} md={8} className={`${styles.grid_img} grid_img`}>
                <Image placeholder="blur" src={imgSection03} width="891" height="562" layout="responsive" className="img_fluid" alt={t('wonderwalllux:article_03_title_a')+t('wonderwalllux:article_03_title_b')} /> 
              </Grid>

              <Grid item xs={12} md={4} className={`${styles.grid_context} grid_context`}>
                <div className={`${styles.context_wr} context_wr`}>
                  <Typography variant="subtitle1" gutterBottom component="h2" className={`${styles.title} title`}>
                    <span className={`${styles.text_1} text_1`}>{t('wonderwalllux:article_03_title_a')}</span>
                    <span className={`${styles.text_2} text_2`}>{t('wonderwalllux:article_03_title_b')}</span>
                  </Typography>
                  {t('wonderwalllux:article_02_desc') !=""? 
                    <Typography variant="body1" gutterBottom paragraph={true} component="div" sx={{mb:0}} dangerouslySetInnerHTML={{__html: t('wonderwalllux:article_03_desc')}}>
                    </Typography>
                    :
                    ""}
                </div>
              </Grid>
              
            </Grid>
          </Container>

          

        </div>

      

        <Container id="informations" maxWidth="lg" component="section" className={`${styles.section_informations} section_informations`}>
          <Grid container direction="row" justifyContent="space-between" alignItems="flex-start" spacing={2} className={`${styles.grid_container} grid_container`}>

            <Grid item xs={12} md={5} className={`${styles.grid_form} grid_form`}>
              <Typography variant="subtitle1" gutterBottom component="h2" className={`${styles.title} title`}>
                {t('common:form_title')}
              </Typography>
              <Form collection="Wonderwall Lux"/>
            </Grid>

            <Grid item xs={12} md={6} className={`${styles.grid_datasheets} grid_datasheets`}>
              <Typography variant="subtitle1" gutterBottom component="h2" className={`${styles.title} title`}>
                {t('common:datasheets')}
              </Typography>

              {/* <List className={`${styles.list_download} list_download`}>
                {t('wonderwalllux:download_files', {returnObjects: true}).map(({label, file}) => (
                  <ListItem className={`${styles.list_item} list_item`} key={label}>
                    <ListItemText className={`${styles.list_label} list_label`} primary={label} />
                    <a className={`${styles.list_link} list_link`} href={"/download/wonderwall-lux/"+file+".pdf"} aria-label={t('common:download')+ " "+label} title={t('common:download')+ " "+label}  download> 
                      <ListItemText className={`${styles.list_download} list_download`} primary={t('common:download')} />
                    </a>
                  </ListItem>
                ))}
              </List> */}

              <List className={`${styles.list_download} list_download`}>
                {t('wonderwalllux:download_files', {returnObjects: true}).map(({label, file}) => (
                  <ListItem className={`${styles.list_item} list_item`} key={label}>
                    <ListItemText className={`${styles.list_label} list_label`} primary={label} />
                    <a className={`${styles.list_link} list_link`} href={"/download/wonderwall-lux/"+file+".pdf"} aria-label={t('common:download')+ " "+label} title={t('common:download')+ " "+label}  download> 
                      <ListItemText className={`${styles.list_download} list_download`} primary={t('common:download')} />
                    </a>
                  </ListItem>
                ))}
              </List>

            </Grid>

          </Grid>
        </Container>
        
        
      </main>

      <Footer/>
      
    </>
  )
}
export default WonderwallLuxPage;